'use client';

import { usePathname, useRouter } from 'next/navigation';

import {
  Avatar,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Link,
  PrimaryLightChip,
} from '@salesport/ui-components';

import { UserProfile } from '@/common/types';
import { handleRequest } from '@/common/utils/auth-helpers/client';
import { SignOut } from '@/common/utils/auth-helpers/server';
import { getUserInitials } from '@/common/utils/helpers';
import { clientEnv } from '@/env/client-env';
import routes from '@/routes';
import { useCreditBalance } from './hooks';

interface UserProfileDropdownProps {
  user: UserProfile;
}

export function UserProfileDropdown({ user }: UserProfileDropdownProps) {
  const creditBalance = useCreditBalance();

  return (
    <div className="align-center relative flex flex-row justify-between py-4 md:py-6">
      <div className="align-center flex justify-end space-x-8">
        <ProfileDropdown user={user} creditBalance={creditBalance} />
      </div>
    </div>
  );
}

type ProfileDropdownProps = {
  user: UserProfile;
  creditBalance?: number;
};
const ProfileDropdown: React.FC<ProfileDropdownProps> = ({
  user,
  creditBalance = 0,
}) => {
  const accountPageUrl = routes.account.build();
  const routesPageUrl = clientEnv.spContactsUrl;
  const userInitials =
    user.full_name != null ? getUserInitials(user.full_name) : 'Me';

  return (
    <Dropdown placement="bottom-end">
      <DropdownTrigger>
        <Avatar
          as="button"
          className="transition-transform"
          name={userInitials}
          size="sm"
          src={user.avatar_url ?? undefined}
        />
      </DropdownTrigger>

      <DropdownMenu aria-label="Profile Actions" variant="flat">
        <DropdownItem key="profile" className="gap-2">
          <UserDetailsRow user={user} />
        </DropdownItem>
        <DropdownItem key="settings">
          <Link size="sm" color="foreground" href={accountPageUrl}>
            Account Settings
          </Link>
        </DropdownItem>
        <DropdownItem key="team_settings" showDivider>
          <SubscriptionWithCreditsMenuItem creditBalance={creditBalance} />
        </DropdownItem>

        <DropdownItem key="analytics">
          <Link
            size="sm"
            color="foreground"
            href={routesPageUrl}
            isExternal
            showAnchorIcon>
            Help & Information
          </Link>
        </DropdownItem>
        <DropdownItem key="system">
          <LogOutMenuItem />
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

type UserDetailsRowProps = {
  user: UserProfile;
};
const UserDetailsRow: React.FC<UserDetailsRowProps> = ({ user }) => {
  const userInitials =
    user.full_name != null ? getUserInitials(user.full_name) : 'Me';
  return (
    <div className="flex gap-2">
      <div className="flex">
        <Avatar
          className="transition-transform"
          name={userInitials}
          size="md"
          src={user.avatar_url ?? undefined}
        />
      </div>
      <div>
        <p>{user.full_name ?? 'Me'}</p>
        <p>{user.email}</p>
      </div>
    </div>
  );
};

type SubscriptionWithCreditsMenuItemProps = {
  creditBalance: number;
};
const SubscriptionWithCreditsMenuItem: React.FC<
  SubscriptionWithCreditsMenuItemProps
> = ({ creditBalance }) => {
  const pricingPageUrl = routes.pricing.build();
  return (
    <Link size="sm" color="foreground" href={pricingPageUrl}>
      <div className="flex items-center justify-between gap-2">
        <p>Subscription</p>
        <PrimaryLightChip size={'sm'}>Credits {creditBalance}</PrimaryLightChip>
      </div>
    </Link>
  );
};

const LogOutMenuItem: React.FC = () => {
  const router = useRouter();
  return (
    <form onSubmit={(e) => handleRequest(e, SignOut, router)}>
      {/* eslint-disable-next-line */}
      <input type="hidden" name="pathName" value={usePathname()} />
      <button type="submit">Sign out</button>
    </form>
  );
};
